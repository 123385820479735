<template>
  <div class="border-t border-gray-100 py-10 content-center text-center opacity-50">
    <p class="text-xs text-gray-500 mb-2">
      Event Ticketing Powered By
    </p>
    <img src="~/assets/wetix-logo-small-black.png" alt="WeTix" class="h-7 self-center mx-auto mb-4">

    <p class="text-xs text-gray-500 mt-8">
      &copy; {{ new Date().getFullYear() }} WeTix Ltd. All rights reserved.
    </p>
  </div>
</template>
